<!--研修活动-->
<template>
  <div class="dynamic-container"
       @click="toDetail(item)">
    <div class="image-box">
      <img :src="item.coverUrl" alt="">
    </div>
    <div class="desc-box">
      <div class="desc-title">{{ item.courseTitle }}</div>
      <div class="desc-subtitle">发起者：{{ item.teacher }}</div>
      <div class="desc-subtitle">时间：{{ item.courseStartDate + ' ' + item.courseStartTime + '至' + item.courseEndDate + ' ' + item.courseEndTime }}</div>
      <div class="desc-subtitle">学段学科：{{ item.grade + '、' + item.subject }}</div>
    </div>
    <div class="right-box">
      <slot name="rightBox"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "dynamicItem",
    props: {
      item: []
    },
    mounted() {},
    methods: {
      toDetail(item) {
        this.$router.push({
          name: 'yxActivityDetail',
          params: {
            id: item.id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.dynamic-container {
  padding: 24px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  cursor: pointer;
  .image-box {
    width: 210px;
    height: 118px;
    overflow: hidden;
    border: 1px solid #ddd;
    padding: 10px;
    margin-right: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .desc-box {
    flex: 1;
    height: 118px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div {
      text-align: left;
    }
    .desc-title {
      font-size: 18px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .desc-subtitle {
      font-size: 15px;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .desc-time {
      font-size: 14px;
      color: #999;
    }
  }
}
</style>