<template>
	<div class="home-container">
		<div class="content-div">
			<navigation></navigation>
			<homeBanner></homeBanner>
			<div class="home-middle-box">
				<a href="http://www.bjtzeduyun.com/tzjyy/frontview/index.html" target="_blank">
					<img src="../../assets/jy.png" alt="">
				</a>
				<!--<a href="" target="_blank">-->
				<img src="../../assets/zy.png" alt="">
				<!--</a>-->
				<a href="http://sso.bjtzeduyun.com/login?service=http%3A%2F%2Foa.bjtzeduyun.com%2Fseeyon%2Findex.jsp"
					target="_blank">
					<img src="../../assets/oa.png" alt="">
				</a>
				<!--<a href="" target="_blank">-->
				<img src="../../assets/zyzx.png" alt="">
				<!--</a>-->
				<!--<a href="" target="_blank">-->
				<img src="../../assets/zsxt.png" alt="">
				<!--</a>-->
			</div>
			<div style="display: flex;width: 100%;justify-content: space-between;">
				<div class="left-list-box">
					<div class="dynamic-list-title">
						<div class="dynamic-list-title-left">研修动态</div>
						<div class="moreBtn dynamic-list-title-right" @click="toDongtaiList">更多</div>
					</div>
					<dynamicItem1 v-for="(item, index) in studyNewsList" :studyNewsItem="item" :key="index">
					</dynamicItem1>
				</div>
				<div>
					<div class="right-list-box">
						<div class="dynamic-list-title">
							<div class="dynamic-list-title-left">中心简介</div>
							<div class="moreBtn dynamic-list-title-right" @click="toIntroduction">更多</div>
						</div>
						<div class="center-desc">
							<div class="center-desc-item" @click="toIntroduction('historical')">
								<img src="../../assets/ls.png" alt="">
							</div>
							<div class="center-desc-item" @click="toIntroduction('leadership')">
								<img src="../../assets/xr.png" alt="">
							</div>
							<div class="center-desc-item" @click="toIntroduction('partyUnion')">
								<img src="../../assets/dj.png" alt="">
							</div>
							<div class="center-desc-item" @click="toIntroduction('organization')">
								<img src="../../assets/zz.png" alt="">
							</div>
						</div>
					</div>
					<div class="right-list-box right-list-box2">
						<div class="dynamic-list-title">
							<div class="dynamic-list-title-left">研修活动 <span @click="toCms"
									style="font-size: 12px;color: #767676;cursor: pointer">后台入口</span> </div>
							<div style="display: flex;align-items: center;justify-content: flex-start;">
								<div class="sort-div" @click="courseSortClick('create_time')">
									<img v-if="queryParams.sortField === 'create_time'" src="../../assets/fbsj_Selected.png" alt="">
									<span v-else>发布时间</span>
								</div>
								<div class="sort-div" @click="courseSortClick('concur_sign_up')">
									<img v-if="queryParams.sortField === 'concur_sign_up'" src="../../assets/bmrs_Selected.png" alt="">
									<span v-else>报名人数</span>
								</div>
								<!-- <div class="sort-div" @click="courseSortClick('hot')">
									<img v-if="courseSortBy === 'hot'" src="../../assets/rd_Selected.png" alt="">
									<span v-else>热度</span>
								</div> -->
								<div class="moreBtn dynamic-list-title-right" style="font-size: 16px;margin-left: 20px"
									@click="toActivityList">更多</div>
							</div>
						</div>
						<div v-for="(item, index) in courseList" :key="index" @click="toYxDetail(item)"
							class="active-item">
							<div class="img-box" style="width: 50px;height: 50px;margin-right: 15px">
								<img style="width: 50px;height: 60px;margin-top: 10px"
									:src="require(`../../assets/top${index + 1}.png`)" alt="">
							</div>
							<div class="active-item-left">
								<div class="item-title">{{ item.title }}</div>
								<div class="item-date">发布时间：{{ item.createTime }}</div>
							</div>
							<div class="active-item-right">
								<div style="width: 70px">报名人数</div>
								<div class="active-num">{{ item.concurSignUp }}</div>
								<!-- <div style="color:#2C9FE8;margin-top: 10px;font-size: 16px;">
									热度：{{ item.scoreExcellentNum }}</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="link-div"> -->
			<!-- <div class="link-div-title">
				友情链接
			</div> -->
			<!-- <div class="link-item-list"> -->
				<!--<div><a href="">通州区教委</a></div>-->
				<!--<div>北京教育资源网</div>-->
				<!-- <div><a href="https://cache.bdschool.cn/public/bdschool/index/static/migu/w.html?v=2020041901"
						target="_blank">北京数字学校</a></div>
				<div><a href="http://www.bjedures.com/mrsp/bjmrsp/index.do?t=0.3325993265907139"
						target="_blank">北京市数字教育资源管理与共享交换平台</a></div> -->
				<!--<div>北京市中小学课程教材资源网</div>-->
				<!-- <div><a href="https://www.eduyun.cn/" target="_blank">国家教育资源公共服务平台</a></div> -->
			<!-- </div> -->
			<!-- <div style="width: 100%;height: 35px;background-color: #f7f7f7"></div> -->
		<!-- </div> -->
		<adv></adv>
	</div>
</template>

<script>
	import cookieUtil from "@/util/cookieUtil"
	import navigation from '@/components/navigation'
	import dynamicItem from '@/components/dynamicItem'
	import dynamicItem1 from '@/components/dynamicItem1'
	import homeBanner from '@/components/homeBanner'
	import adv from '@/components/advertisement'
	import {
		activityListAnonApi,
	} from '@/api/newApi';
	export default {
		name: "index",
		components: {
			navigation,
			dynamicItem,
			dynamicItem1,
			homeBanner,
			adv
		},
		data() {
			return {
				courseList: [],
				studyNewsList: [],
				courseSortBy: "time",
				queryParams:{
					pageNum: 1,
					pageSize: 10,
					sortField:'create_time',
					sortOrder:'DESC',
				},
			}
		},
		mounted() {
			if (this.$route.query.JSESSIONID) {
				cookieUtil.delCookie('JSESSIONID')
				cookieUtil.setCookie({
					key: 'JSESSIONID',
					value: this.$route.query.JSESSIONID
				})
			}
			this.getList()
			this.getStudyNews()
		},
		methods: {
			getList() {
				activityListAnonApi({
					sortField:this.queryParams.sortField,
					sortOrder:'DESC',
					offset: (parseInt(this.queryParams.pageNum) - 1) * this.queryParams.pageSize,
					limit: this.queryParams.pageSize
				}).then(res => {
					const {
						code,
						result
					} = res;
					if (code == 200) {
						this.courseList = result.rows.map(item=>{
							item.concurSignUp = item.concurSignUp?item.concurSignUp:0;
							return item
						});
						this.total = result.total || 0
					}
				})
			},
			courseSortClick(type) {
				this.queryParams.sortField = type;
				this.getList()
			},
			toIntroduction(selectComId) {
				this.$router.push({
					name: 'centerIntroduction',
					query: {
						selectComId
					}
				})
			},
			toActivityList() {
				this.$router.push({
					name: 'yxActivity'
				})
			},
			toDongtaiList() {
				this.$router.push({
					name: 'yxdongtai'
				})
			},
			getCourseList() {
				let {
					courseSortBy
				} = this
				let sortField = ""
				if (courseSortBy === 'time') {
					sortField = 'course_start_date'
				}
				if (courseSortBy === 'hot' || courseSortBy === 'signUp') {
					sortField = 'concur_sign_up'
				}
				this.$api.home.getCourseList({
					pageSize: 7,
					sortField,
					pageNum: 1
				}).then(res => {
					(res.code === '200') && (this.courseList = res.result.rows)
				})
			},
			toYxDetail(item) {
				this.$router.push({
					name: 'yxActivityDetail',
					params: {
						id: item.id
					}
				})
			},
			getStudyNews() {
				this.$api.home.getStudyNews({
					offset: 0,
					limit: 7
				}).then(res => {
					this.studyNewsList = res.result.rows
				})
			},
			toCms() {
				// console.log(this.baseConfig.cmsUrl)
				window.open(this.baseConfig.cmsUrl)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content-div {
		width: 100%;
		max-width: 1200px;
		margin: auto;
		background-color: #f7f7f7;
		padding-bottom: 35px;

		.home-middle-box {
			width: 100%;
			display: flex;
			justify-content: space-between;
			background-color: #f7f7f7;
			padding: 30px 0;

			img {
				width: 221px;
				height: 120px;
			}
		}
	}

	.link-div {
		width: 1200px;
		margin: auto auto 40px;
		background-color: #fff;

		.link-div-title {
			width: 100%;
			height: 70px;
			line-height: 70px;
			font-size: 18px;
			font-family: PingFangSC-Medium;
			color: #4A4A4A;
			letter-spacing: 0;
			text-align: left;
			border-bottom: 1px solid #ddd;
			padding: 0 20px;
		}

		.link-item-list {
			display: flex;
			margin: 0 20px;

			&>div {
				padding: 20px 20px;
				cursor: pointer;
				font-size: 14px;
			}

			a {
				color: #ff0000;
				text-decoration: none
			}

			&>div:hover {
				color: #409EFF;
			}
		}
	}

	.left-list-box {
		width: 720px;
		background-color: #FFF;
		padding: 0 26px;

		.dynamic-list-title {
			width: 100%;
			height: 70px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #ddd;

			.dynamic-list-title-left {
				font-size: 22px;
				font-weight: bolder;
				color: #4A4A4A;
			}

			.dynamic-list-title-right {
				font-size: 16px;
				color: #4A4A4A;
			}

			.dynamic-list-title-right:hover {
				color: #409EFF;
			}
		}
	}

	.right-list-box {
		width: 460px;
		height: 230px;
		background-color: #FFF;
		padding: 0 26px;

		.sort-div {
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #4A4A4A;
			letter-spacing: 0.55px;
			max-width: 90px;
			margin-right: 10px;
			height: 30px;
			line-height: 30px;
			cursor: pointer;
		}

		.dynamic-list-title {
			width: 100%;
			height: 70px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #ddd;

			.dynamic-list-title-left {
				font-size: 22px;
				font-weight: bolder;
				color: #4A4A4A;
			}

			.dynamic-list-title-right {
				font-size: 16px;
				color: #4A4A4A;
			}

			.dynamic-list-title-right:hover {
				color: #409EFF;
			}
		}

		.center-desc {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding: 35px 0 30px;

			.center-desc-item {
				width: 90px;
				height: 90px;
				cursor: pointer;

				img {
					width: 100%;
				}
			}
		}
	}

	.right-list-box2 {
		margin-top: 30px;
		height: auto;

		.active-item {
			display: flex;
			padding: 24px 0;
			height: 124px;
			border-bottom: 1px solid #eee;
			cursor: pointer;

			.active-item-left {
				flex: 1;
				text-align: left;

				.item-title {
					width: 288px;
					font-size: 16px;
					color: #1E1E1E;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-box-orient: vertical;
				}

				.item-date {
					font-size: 14px;
					color: #767676;
					;
				}
			}

			.active-item-right {
				width: 80px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start !important;
				align-items: center;

				.active-num {
					width: 60px;
					height: 26px;
					line-height: 25px;
					margin-top: 10px;
					border: 1px solid #409EFF;
					color: #409EFF;
					border-radius: 25px;
					text-align: center;
				}
			}

			.active-item-left,
			.active-item-right {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}

		.active-item:hover .item-title {
			color: #409EFF;
		}
	}

	.moreBtn {
		cursor: pointer;
	}

	.home-bj {
		position: absolute;
	}
</style>