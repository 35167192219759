import request from '@/util/request'

//登陆获取用户信息
export function userInfo(query) {
	return request({
		url: '/user/login',
		method: 'get',
		params: query
	})
}

/**
 * 字典项
 */

//科目选项
export function subjectOptionApi(query) {
	return request({
		url: '/subject/option',
		method: 'get',
		params: query
	})
}
//年级树选项
export function gradeTreeApi(query) {
	return request({
		url: '/grade/tree',
		method: 'get',
		params: query
	})
}
// 学校列表
export function schoolListApi(query) {
	return request({
		url: '/school/list',
		method: 'get',
		params: query
	})
}
/**
 * 活动管理
 */
// 列表
export function activityListApi(query) {
	return request({
		url: '/activity/list',
		method: 'get',
		params: query
	})
}
// 列表匿名
export function activityListAnonApi(query) {
	return request({
		url: '/activity/anon/list',
		method: 'get',
		params: query
	})
}
//详情
export function activityInfoApi(query) {
	return request({
		url: '/applets/activity/detail',
		method: 'get',
		params: query
	})
}
//评价
export function activityEvaluationApi(data) {
	return request({
		url: '/applets/activityEvaluation',
		method: 'post',
		data: data
	})
}
/**
 * 活动报名
 * @param {Object} data
 */
export function activitySignUpAddApi(data) {
	return request({
		url: '/applets/activitySignUp',
		method: 'post',
		data: data
	})
}
/**
 * 取消活动报名
 * @param {Object} data
 */
export function activitySignUpDelApi(data) {
	return request({
		url: '/applets/activitySignUp',
		method: 'DELETE',
		data: data
	})
}
//修改个人信息
export function userSelfEditApi(data) {
	return request({
		url: '/user/self',
		method: 'post',
		data: data
	})
}