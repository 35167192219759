<!--研修动态-->
<template>
  <div class="dynamic-container"
       @click="toDetail(studyNewsItem)">
    <div class="image-box">
      <img :src="studyNewsItem.backPic" alt="">
    </div>
    <div class="desc-box">
      <div class="desc-title">{{ studyNewsItem.newsTitle }}</div>
      <div class="desc-subtitle">{{ studyNewsItem.briefIntroduction }}</div>
      <div class="desc-subtitle">发布时间：{{ studyNewsItem.createTime }}</div>
    </div>
    <div class="right-box">
      <slot name="rightBox"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "studyNewsItem",
    props: {
      studyNewsItem: {}
    },
    mounted() {
    },
    methods: {
      toDetail(studyNewsItem) {
        this.$router.push({
          name: 'yxdongtaiDetail',
          params: {
            id: studyNewsItem.id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.dynamic-container {
  width: 668px;
  padding: 18px 0 18px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  .image-box {
    width: 222px;
    height: 130px;
    padding: 6px;
    border-radius: 3px;
    img {
      width: 210px;
      height: 118px;
    }
  }
  .desc-box {
    width: 434px;
    height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 18px;
    div {
      text-align: left;
    }
    font-family: PingFangSC-Regular;
    .desc-title {
      font-size: 18px;
      color: #1E1E1E;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      letter-spacing: 0;
    }
    .desc-subtitle {
      font-size: 14px;
      color: #4A4A4A;
      overflow: hidden;
      text-overflow: ellipsis;
      display:-webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .desc-time {
      font-size: 14px;
      color: #767676;
    }
  }
}
.dynamic-container:hover {
  .image-box {
    box-shadow: 0 0 5px rgba(0,0,0,.12);
  }
  .desc-title {
    color: #2C9FE8;
  }
}
</style>